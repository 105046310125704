export default {
  state: () => ({
    pagename: '',
    effect: false,
    searchForm: {},
    pagination: {},
  }),
  mutations: {
    setPageInfo(state, item) {
      state.pagename = item.pagename;
      state.searchForm = item.searchForm;
      state.pagination = item.pagination;
      /*if (item.searchForm) {
        for (let sf of item.searchForm) {
          state.searchForm[sf.type] = sf.strategy;
        }
      }*/
    },
    setEffect(state, item) {
      state.effect = item;
    }

  }
}